import pinyin from "pinyin";
import Vue from "vue";

class utils {
  /**
   * @data 对象，将对象转换为字符串拼接的方式
   */
  setUrl = (data, str = "?") => {
    for (let key in data) {
      str = str + key + "=" + data[key] + "&";
    }
    return str.slice(0, str.length - 1);
  };

  /**
   * @time 时间
   * 	将秒切换为时间
   **/
  setTime = (time) => {
    const tem = (x) => {
      return String(x).length == 1 ? "0" + x : x;
    };
    let house = tem(Math.floor(time / 60 / 60));
    let minute = tem(Math.floor((time / 60) % 60));
    let second = tem(Math.floor(time % 60));
    return house + ":" + minute + ":" + second;
  };

  /**
   * @first  第一个对象
   * @scond  第二个对象
   * @ 判断first 和 scond 两个对象是否全等
   **/
  isEqual = (first, scond) => {
    // 通过 Object.keys()  将对象的键名转成数组
    const firstArr = Object.keys(first);
    const scondArr = Object.keys(scond);

    // 如果长度不相等
    if (firstArr.length != scondArr.length) return false;

    // 递归判断是否全等
    for (let k in first) {
      if (typeof first[k] == "object" || typeof scond[k] == "object") {
        if (!isEqual(first[k], scond[k])) return false;
      } else if (first[k] != scond[k]) return false;
    }
    return true;
  };

  /**
   * @url  下载地址
   * @name  下载名称
   * @ h5下载函数 微信浏览器不支持下载 需到其他浏览器下载
   **/
  download(url, name) {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const a = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        a.download = name;
        a.href = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      });
  }
  /**
   * @chineseStrings  汉字字符串数组
   * 用于获取以字母排序的对象数组内有name（字母）和child（以字母开头的数据）
   **/
  setPinArr(chineseStrings) {
    const groupedByInitials = {};
    chineseStrings.forEach((str) => {
      // 获取每个字符串第一个汉字的首字母并转为大写
      const initial = pinyin(str[0], {
        style: pinyin.STYLE_FIRST_LETTER,
        heteronym: true,
        segment: true,
      })[0][0].toUpperCase();
      // 如果这个首字母还没有对应的数组，就创建一个
      if (!groupedByInitials[initial]) {
        groupedByInitials[initial] = [];
      }
      // 将汉字字符串添加到对应的数组中
      groupedByInitials[initial].push(str);
    });

    // 将对象转换为需要的数组格式，并按字母排序
    const result = Object.keys(groupedByInitials)
      .sort()
      .map((name) => ({
        name,
        child: groupedByInitials[name].sort(),
      }));
    return result;
  }
  /**
   *
   * @param {} startColor 开始的颜色
   * @param {} steps 步长（数组长度）
   * @returns 一个rgb颜色渐变数组
   */
  createColorGradient(startColor, steps) {
    var colors = [];
    var stepFactor = {
      red: startColor.red / steps,
      green: startColor.green / steps,
      blue: startColor.blue / steps,
    };

    for (var i = 0; i <= steps; i++) {
      var red = Math.max(startColor.red - stepFactor.red * i, 0);
      var green = Math.max(startColor.green - stepFactor.green * i, 0);
      var blue = Math.max(startColor.blue - stepFactor.blue * i, 0);
      colors.push(
        "rgb(" +
          Math.floor(red) +
          "," +
          Math.floor(green) +
          "," +
          Math.floor(blue) +
          ")"
      );
    }

    return colors;
  }
  scrollToElement(type, num = 116) {
    // 获取目标元素的位置
    let elementPosition = "";
    if (type && type.length) {
      elementPosition = type[0].getBoundingClientRect().top;
    } else {
      return;
    }
    // 获取当前可视范围的顶部距离文档顶部的距离
    const offsetPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    // 计算总的滚动位置
    const scrollToPosition = elementPosition + offsetPosition;

    // 平滑滚动到指定位置
    window.scrollTo({
      top: scrollToPosition - num,
      behavior: "smooth",
    });
  }
}
const baseUrl = "http://zehao.luowangkeji.com/";
const util = new utils();

export { baseUrl, util };
