import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import shopList from "./components/shopList";
import goods from "./components/goods";
import bigImg from "./components/bigImg";

import recom from "./components/recom";

import { util } from "./utils/util";
import { Api } from "./utils/apis";
import pinyin from "pinyin";

util.Go = (url, data, name) => {
  let href = window.location.href.split("#")[1];
  if (name instanceof String) {
    let bread = JSON.parse(localStorage.getItem("bread"));
    bread[1] = { path: "", name };
    localStorage.setItem("bread", JSON.stringify(bread));
  } else if(name instanceof Object){
    let bread = JSON.parse(localStorage.getItem("bread"));
    bread = [bread[0]]
    name.forEach((item, i) => {
      bread[i + 1] = item
    });
    localStorage.setItem("bread", JSON.stringify(bread));
  }
  if (href !== url + util.setUrl(data)) {
    console.log(url,"NavigationDuplicated")
    router.push(url + util.setUrl(data)).catch((err) => {
      if (err.name === "NavigationDuplicated") {
        // 吞掉错误，或者你可以选择记录下来
        console.log(err);
      } else {
        // 处理其他导航错误
        console.error(err);
      }
    });
  }
};

Vue.component("shopList", shopList);
Vue.component("goods", goods);
Vue.component("big-img", bigImg);
Vue.component("recom", recom);

Vue.prototype.$util = util;
Vue.prototype.$Api = new Api();
Vue.prototype.$pinyin = pinyin;
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
