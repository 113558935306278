// src/api/apis.js
import reqs from "@/utils/request";

class Api {

  // 个人管理
  loginByTel = (data) => reqs.post("User/loginByTel", data); // 手机号登录
  LoginByPwd = (data) => reqs.post("User/LoginByPwd", data); // 账号密码登录
  sendCode = (data) => reqs.get("User/sendCode", data); // 登录/注册 发送短信验证码
  register = (data) => reqs.post("User/register", data); // 注册
  updatePassword = (data) => reqs.post("User/update_password", data); // 修改密码
  UserDetail = (data) => reqs.get("User/detail", data); // 用户详情
  payConfig = (data) => reqs.get("order/get_pay_config", data); // 支付配置
  invoiceList = (data) => reqs.get("Invoice/lists", data); // 支付配置
  invoiceAdd = (data) => reqs.post("Invoice/add", data); // 支付配置
  invoiceEdit = (data) => reqs.post("Invoice/edit", data); // 支付配置
  invoiceDefault = (data) => reqs.post("Invoice/setDefault", data); // 支付配置
  invoiceDelete = (data) => reqs.post("Invoice/delete", data); // 支付配置

  updataPhone = (data) => reqs.get("User/update_user_phone", data); // 换绑手机号
  updataEmail = (data) => reqs.get("User/update_invoice_email", data); // 换绑发票邮箱

  // 订单管理
  orderLists = (data) => reqs.get("user.order/lists", data); // 订单列表
  orderDetail = (data) => reqs.get("user.order/detail", data); // 订单详情
  cancelOrder = (data) => reqs.get("user.order/cancel", data); // 取消订单
  orderNow = (data) => reqs.get("order/buyNow", data); // 订单详情
  orderCart = (data) => reqs.get("order/cart", data); // 订单详情
  confirmOrder = (data) => reqs.get("user.order/receipt", data); // 确认收货

  // 商品管理
  goodsDetail = (data) => reqs.get("goods/detail", data); // 商品详情
  goodsLists = (data) => reqs.get("goods/lists", data); // 商品列表
  category = (data) => reqs.get("category/index", data); // 商品分类
  goodsSearch = (data) => reqs.get("wxapp.formId/save", data); // 商品搜索
  getSpecByCategoy = (data) => reqs.get("category/getSpecByCategoy", data); // 根据商品分类查询规格
  getSpecBySearch = (data) => reqs.get("category/getSpecBySearch", data); // 根据商品分类查询规格
  getSpecBySearchAndScreenId = (data) => reqs.get("category/getSpecBySearchAndScreenId", data); // 根据商品分类查询规格
  getCategoryByWord = (data) => reqs.get("category/get_category_by_title", data); // 根据关键词查询分类

  // 购物车管理
  cartLists = (data) => reqs.get("cart/lists", data); // 购物车列表
  cartAdd = (data) => reqs.post("cart/add", data); // 添加购物车
  cartSub = (data) => reqs.post("cart/sub", data); // 减少购物车
  cartEdit = (data) => reqs.post("Cart/edit_num", data); // 减少购物车
  cartDelete = (data) => reqs.post("cart/delete", data); // 删除购物车

  // 地址管理
  addressLists = (data) => reqs.get("address/lists", data); // 地址列表
  addressAdd = (data) => reqs.post("address/add", data); // 添加地址
  addressSetDefault = (data) => reqs.post("address/setDefault", data); // 设置默认地址
  addressDelete = (data) => reqs.post("address/delete", data); // 删除地址address/delete
  addressEdit = (data) => reqs.post("address/edit", data); // 编辑地址address/edit
  getDelivery = (data) => reqs.post("Goods/get_delivery_price", data); // 获取地区运费

  // 支付下单
  OrderBuyNow = (data) => reqs.post("order/buyNow", data); // 立即购买
  OrderBuyCart = (data) => reqs.post("order/cart", data); // 立即购买
  navigation = (data) => reqs.get("navigation/index", data); // 导航栏
  Article = (data) => reqs.get("Article/lists", data); // 首页banner详图
  getContentData = (data) => reqs.get("Article/getContentData", data); // 首页banner详图

  // 新闻模块
  ArticleLists = (page, limit) =>
    reqs.get("Article/lists", {
      page,
      limit: limit || 20,
    }); // 新闻列表
  ArticleDetail = (data) => reqs.get("Article/detail", data); // 新闻详情

  // 知识百科
  knowledge_category = (data) => reqs.get("knowledge_category/index", data); // 知识分类
  KnowledgeLists = (data) => reqs.get("knowledge/lists", data); // 知识列表
  KnowledgeDetail = (data) => reqs.get("knowledge/detail", data); // 知识详情

  // 品牌模块
  ShopLists = (data) => reqs.get("shop/lists", data); // 品牌列表/lists
  ShopDetail = (data) => reqs.get("shop/detail", data); // 品牌详情/detail


}

export { Api };
