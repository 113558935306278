import Vue from "vue";
import VueRouter from "vue-router";
import { Api } from "../utils/apis";
const api = new Api()
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/classify-3",
    name: "classify-3",
    component: () => import("../views/classify-3.vue"),
  },
  {
    path: "/classify-2",
    name: "classify-2",
    component: () => import("../views/classify-2.vue"),
  },
  {
    path: "/classify",
    name: "classify",
    component: () => import("../views/classify.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/detail.vue"),
  },
  {
    path: "/active",
    name: "active",
    component: () => import("../views/active.vue"),
  },
  {
    path: "/shopDetail",
    name: "shopDetail",
    component: () => import("../views/shopDetail.vue"),
  },
  {
    path: "/knowledge",
    name: "knowledge",
    component: () => import("../views/footer/knowledge.vue"),
  },
  {
    path: "/knowDetail",
    name: "knowDetail",
    component: () => import("../views/footer/knowDetail.vue"),
  },
  {
    path: "/brand",
    name: "brand",
    component: () => import("../views/footer/brand.vue"),
  },
  {
    path: "/category",
    name: "category",
    component: () => import("../views/footer/category.vue"),
  },
  {
    path: "/recommendation",
    name: "recommendation",
    component: () => import("../views/footer/recommendation.vue"),
  },
  {
    path: "/Richtext",
    name: "Richtext",
    component: () => import("../views/footer/Rich_text.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/footer/new/news.vue"),
  },
  {
    path: "/new/detail",
    name: "newDetail",
    component: () => import("../views/footer/new/detail.vue"),
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => import("../views/footer/introduce.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/footer/contact.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/footer/privacy.vue"),
  },
  {
    path: "/orderDetail",
    name: "orderDetail",
    component: () => import("../views/my/order/orderDetail.vue"),
  },
  {
    path: "/my",
    name: "my",
    component: () => import("../views/my/my.vue"),
    children: [
      {
        path: "order",
        name: "order",
        component: () => import("../views/my/order/order.vue"),
      },
      {
        path: "Aftersales",
        name: "Aftersales",
        component: () => import("../views/my/order/After-sales.vue"),
      },
      {
        path: "userInfo",
        name: "userInfo",
        component: () => import("../views/my/userInfo/userInfo.vue"),
      },
      {
        path: "openFile",
        name: "openFile",
        component: () => import("../views/my/userInfo/openFile.vue"),
      },
      {
        path: "info",
        name: "info",
        component: () => import("../views/my/userInfo/info.vue"),
      },
      {
        path: "setPass",
        name: "setPass",
        component: () => import("../views/my/userInfo/setPass.vue"),
      },
      {
        path: "address",
        name: "address",
        component: () => import("../views/my/userInfo/address.vue"),
      },
    ],
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("../views/feedback.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/regin",
    name: "regin",
    component: () => import("../views/regin.vue"),
  },
  {
    path: "/reginSuccess",
    name: "reginSuccess",
    component: () => import("../views/reginSuccess.vue"),
  },
  {
    path: "/card",
    name: "card",
    component: () => import("../views/card.vue"),
  },
  {
    path: "/Structural",
    name: "Structural",
    component: () => import("../views/Structural.vue"),
  },
  {
    path: "/promotion",
    name: "promotion",
    component: () => import("../views/promotion.vue"),
  },
  {
    path: "/pay",
    name: "pay",
    component: () => import("../views/pay.vue"),
  },
  {
    path: "/chckout",
    name: "chckout",
    component: () => import("../views/chckout.vue"),
  },
];

const router = new VueRouter({
  routes,
});
const whiteListRoutes = ["/pay", "/card", "/my"]; // 这里配置不需检查的路由路径
router.beforeEach(async (to, from, next) => {
  // 获取地址库
  if (localStorage.getItem("krb-token")) {
    if (!(JSON.parse(localStorage.getItem("userInfo")) instanceof Object)) {
      let { userInfo } = await api.UserDetail();
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  }
  const isAuthorized = !!getKrbToken(); // 功能伪代码，假设getKrbToken()用于获取当前的认证信息
  if (isAuthorized) {
    // 如果用户已认证（有token），正常导航
    next();
  } else {
    // 用户未认证
    if (whiteListRoutes.some((item) => to.path.includes(item))) {
      // 如果是白名单中的路由，正常导航
      next("/login");
    } else {
      // 用户未认证且不在白名单中，跳转到登录页面
      next();
    }
  }
});

function getKrbToken() {
  // 功能伪代码，返回用户的krb-token，实际应用中替换为实际的token获取方式
  return localStorage.getItem("krb-token");
}

export default router;
